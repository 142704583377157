<template>
  <div class="container">
    <div class="timezone">
      Timezone: {{ timezone }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'Profile',

  data() {
    return {
      timezone: '',
      isEfficiencyOnlyValue: false
    }
  },

  computed: {
    ...mapGetters(['isEfficiencyOnly', 'me'])
  },

  mounted() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.isEfficiencyOnlyValue = this.isEfficiencyOnly
  },

  methods: {
    ...mapMutations(['setViewLoader']),
    ...mapActions(['fetchActiveUser']),


  }
}
</script>

<style lang="scss" scoped>
.timezone {
  margin-bottom: 30px;
}
</style>
